@tailwind base;
@tailwind components;
@tailwind utilities;

/* Additional styles */
@import "additional-styles/utility-patterns.css";
@import "additional-styles/range-slider.css";
@import "additional-styles/toggle-switch.css";
@import "additional-styles/theme.css";

/* Additional Tailwind directives: https://tailwindcss.com/docs/functions-and-directives/#responsive */
@layer utilities {
  .rtl {
    direction: rtl;
  }
}

/* See Alpine.js: https://github.com/alpinejs/alpine#x-cloak */
[x-cloak=""] {
  display: none;
}

/* DAF5FF */

body {
  background-color: #daf5ff;
  color: #2b3467;
}

/* ::-webkit-scrollbar{
  display: none;
} */
.carousel__container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.carousel__box{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.carousel__images{
  width: auto;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.carousel__images img{
  width: 100%;
  height: 500px;
  object-fit: cover;
  padding: 0;
  margin: 0;
}
.carousel {
  height: 100vh;
}

.carousel .slide {
  width: 100.2% !important;
}

.carousel__body {
  height: 100vh;
}

.appointment__form-group {
  width: 50%;
}

.hero__header{
  width: 100%;
  position: absolute;
  padding:0px 20px;
  top: 180px;
}
.hero__header h1, p{
  color: #FFFFFF;
}
.slogan{
  width: 100%;
}
@media screen and (max-width: 600px) {
  .carousel {
    height: 50vh;
  }
  .carousel__body {
    height: 75vh;
  }
  .layout__header{
    margin-top: 20px;
   
  }
  .clients{
    margin-top: 40px;
   
  }
}

@media screen and (min-width: 600px) and (max-width: 1100px) {
  .carousel {
    height: 60vh;
  }
  .carousel__body {
    height: 75vh;
  }
  .layout__header{
    padding: 25px 0;
  }
  .clients{
    margin-top: 40px;
  }
 
}

@media screen and (min-width: 1100px) and (max-width: 1500px) {
  .carousel {
    height: 65vh;
  }
  .carousel__body {
    height: 80vh;
  }
}

@media screen and (max-width: 570px) {
  .appointment__form-group {
    width: 100%;
    padding: 10px 0;
  }

  .overview,
  .team {
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .servicesContainer {
    margin: 0;
    padding: 0;
  }

  .team__image {
    width: 100%;
    height: 100%;
  }
}
